import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['chatInput', 'chatForm', 'submitBtn', 'messageAttachment']

  static values  = {
    conversationId: Number,
    recieverId:     Number
  }

  connect() {
    console.log('Message form controller connected!');

    const chatInput      = this.chatInputTarget;
    const submitBtn      = this.submitBtnTarget;
    const chatForm       = this.chatFormTarget;
    const conversationId = this.conversationIdValue;
    const recieverId     = this.recieverIdValue;
    const userProfileUrl   = this.chatInputTarget.dataset.userProfileUrl;
    const userInitialsInfo = this.chatInputTarget.dataset.userInitialsInfo;
    const UserId           = this.chatInputTarget.dataset.userId;
    const UserShortName    = this.chatInputTarget.dataset.userShortName;
    const messageAttachment = this.messageAttachmentTarget;

    $(chatInput).focus();

    $(chatInput).on("input", function () {
      if ($(this).val().trim().length > 0) {
        $(submitBtn).prop("disabled", false);
      } else {
        $(submitBtn).prop("disabled", true);
      }
    });

    $(chatInput).on("keydown", async (event) => {
      if ((event.key === "Enter" || event.keyCode === 13) && !event.shiftKey) {
        event.preventDefault();

        // Optimistically append the message
        submitMessage();
        return false;
      }
    });

    $(submitBtn).click(function() {
      submitMessage();
      return false;
    })

    function submitMessage() {
      const hasAttachment = messageAttachment.files.length > 0; // this checks if a file is selected
      if($.trim(chatInput.value) !== '' || hasAttachment) {
        optimisticallyAppendMessage(chatInput.value);
        $(chatForm).submit();
        handleFormSubmission();
      }
    }

    function optimisticallyAppendMessage(content) {
      // content = content.replace(/(\n?\n)+/g, '\r\n');
      const lastMessageTime  = $(chatForm).find('#last_msg_time').val();
      const lastMessageUser  = $(chatForm).find('#last_msg_sender_id').val();
      const lastMsgTimeUtc = new Date(lastMessageTime).toUTCString();

      const tempMessageId = new Date().getTime(); // Create a temporary message ID
      let currentTime = new Date();
      let currentUtcTIme = currentTime.toUTCString();
      const time1 = new Date(currentUtcTIme);
      const time2 = new Date(lastMsgTimeUtc);

      const timediff = Math.floor ((time1 - time2)/ 1000);

      const lastMessageDate = new Date(time2.getFullYear(), time2.getMonth(), time2.getDate());
      const currentMessageDate = new Date(time1.getFullYear(), time1.getMonth(), time1.getDate());
      const diffDays = (currentMessageDate - lastMessageDate)/ (1000 * 60 * 60 * 24);

     
      let messageHtml;
      let userHtml;
      if (userProfileUrl) {
        userHtml = `<img src="${userProfileUrl}" class="g-width-30 g-height-30 rounded-circle g-mr-15 sender-thumb" />`;
      } else {
        userHtml = `<div class="g-width-30 g-height-30 rounded-circle g-mr-15 sender-thumb" data-letters="${userInitialsInfo}"></div>`;
      }

      if (UserId == lastMessageUser && (timediff < 300 ) ) {
           messageHtml = `
            <li class="message-item chat-per child-messages" id="temp-message-${tempMessageId}">
              <div class="d-flex justify-content-start on-chat-thumb">
                <div class="d-block w-100">
                  <div class="d-flex justify-content-between align-items-center message_box g-pos-rel">
                    <p style="white-space: pre-wrap;">${content}</p>
                  </div>
                </div>
              </div>
            </li>
          `
        } else {
          if(diffDays >= 1 ){
            messageHtml = `<div class="date-wrapp" id="temp-chat-separator-${tempMessageId}">
                        <div class="seprator-vertical-line"></div>
                          <div class="chat-start-date">
                          Today
                        </div>
                      </div>
            <li class="message-item chat-per" id="temp-message-${tempMessageId}">
              <div class="d-flex justify-content-start on-chat-thumb">
                ${userHtml}
                <div class="d-block w-100">
                  <h6 class="g-font-size-15 g-mb-0 g-font-weight-600 g-mb-10 message-sent-time">
                    ${UserShortName}
                  </h6>
                  <div class="d-flex justify-content-between align-items-center message_box g-pos-rel">
                    <p style="white-space: pre-wrap;">${content}</p>
                  </div>
                </div>
              </div>
            </li>
          `
          } else {
          messageHtml = `
            <li class="message-item chat-per" id="temp-message-${tempMessageId}">
              <div class="d-flex justify-content-start on-chat-thumb">
                ${userHtml}
                <div class="d-block w-100">
                  <h6 class="g-font-size-15 g-mb-0 g-font-weight-600 g-mb-10 message-sent-time">
                    ${UserShortName}
                  </h6>
                  <div class="d-flex justify-content-between align-items-center message_box g-pos-rel">
                    <p style="white-space: pre-wrap;">${content}</p>
                  </div>
                </div>
              </div>
            </li>
          `
          }
        }

      // Append the optimistic message
      $('#general-message_to_be_append').append(messageHtml);
      setTimeout(scrollToBottom, 100);
      // Add tempMessageId to the form data (so the server receives it)
      const hiddenTempIdField = `<input type="hidden" name="temp_message_id" value="${tempMessageId}" />`;
      const hiddenTempChatSeparatorIdField = `<input type="hidden" name="temp_chat_separator_id" value="temp-chat-separator-${tempMessageId}"/>`
      $(chatForm).append(hiddenTempIdField);
      if(diffDays >=1 ){
        $(chatForm).append(hiddenTempChatSeparatorIdField);
      }
    }

    const scrollToBottom = () => {
      const chat = $('#messages');
      const scrollHeight = chat.prop("scrollHeight");

      chat.animate({
        scrollTop: scrollHeight
      }, 200);

      const proposalChat = $('#proposal-messages');
      const proposalScrollHeight = proposalChat.prop("scrollHeight");

      proposalChat.animate({
        scrollTop: proposalScrollHeight
      }, 200);

    }

    function handleFormSubmission() {
      $("#proposal_text_area").val("");
      $("#message_body").val("");
      $("#message_body").css("height", "");
      $("#message_body_with_attachment").val("");
      $(chatInput).focus();
      $(submitBtn).prop("disabled", true);
    }

    var typingTimer;                //timer identifier
    var doneTypingInterval = 3000;  //time in ms, 3 seconds

    //on keyup, start the countdown
    $(chatInput).on('keyup', function () {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(doneTyping, doneTypingInterval);
    });

    //on keydown, clear the countdown
    $(chatInput).on('keydown', function () {
      broadcastTyping(true);

      clearTimeout(typingTimer);
    });

    function doneTyping () {
      broadcastTyping(null);
    }

    function broadcastTyping(is_typing) {
      $.ajax({
        url: `/broadcast/typing/${conversationId}/${recieverId}`,
        method: "POST",
        data: { is_typing: is_typing }
      });
    }
  }
}
