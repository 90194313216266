/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')

import "@hotwired/turbo-rails"

import "controllers"

Turbo.session.drive = false

window.addEventListener('load', () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('ServiceWorker registered: ', registration);

      var serviceWorker;
      if (registration.installing) {
        serviceWorker = registration.installing;
        console.log('Service worker installing.');
      } else if (registration.waiting) {
        serviceWorker = registration.waiting;
        console.log('Service worker installed & waiting.');
      } else if (registration.active) {
        serviceWorker = registration.active;
        console.log('Service worker active.');
      }

      window.Notification.requestPermission().then(permission => {
        if(permission !== 'granted'){
          throw new Error('Permission not granted for Notification');
        }
      });
    }).catch(registrationError => {
      console.log('Service worker registration failed: ', registrationError);
    });
  }
});


// force to relaod the page when internet connexion is offline to render the offline page in cache
// window.addEventListener('offline', () => {
//   window.location.reload();
// });

// This is for showing online and offline banner when internet is available or not
document.addEventListener("DOMContentLoaded", () => {
  console.log("DOMContentLoaded event fired");

  function showOfflineBanner() {
    if (!document.getElementById("offline-banner")) {
      document.body.insertAdjacentHTML("beforeend", '<div id="offline-banner">You are offline</div>');
    }
  }

  function showOnlineBanner() {
    if (!document.getElementById("online-banner")) {
      document.body.insertAdjacentHTML("beforeend", '<div id="online-banner">You are online</div>');

      // Remove the online banner after 3 seconds
      setTimeout(() => {
        document.getElementById("online-banner")?.remove();
      }, 3000);
    }
  }


  // takes care of network status
  function updateNetworkStatus() {
    const isOffline = sessionStorage.getItem('isOffline');
    if (!navigator.onLine && isOffline) {
      showOfflineBanner();
    } else if(navigator.online && !isOffline){
      document.getElementById("offline-banner")?.remove();
      showOnlineBanner();
    }
  }

  // Check online status on page load
  updateNetworkStatus();

  // Listen for offline & online events
  window.addEventListener("offline", () => {
    console.log("You are offline.");
    sessionStorage.setItem("isOffline", "true");
    showOfflineBanner();
  });

  window.addEventListener("online", () => {
    console.log("Back online.");
    sessionStorage.setItem("isOffline", "false");
    showOnlineBanner();
    document.getElementById("offline-banner")?.remove();
  });

  // Handle when tab becomes visible again
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      updateNetworkStatus();
    }
  });

  // style for online offline banner
  const style = document.createElement("style");
  style.innerHTML = `
    #offline-banner, #online-banner {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      color: white;
      text-align: center;
      font-weight: bold;
      z-index: 9999;
      transition: 1s all ease;
    }
    #offline-banner {
      background: red;
    }
    #online-banner {
      background: green;
    }
  `;
  document.head.appendChild(style);
});

// this loads previpusly displayed page/pages wwhen lid is closed and opened
window.addEventListener("beforeunload", () => {
  sessionStorage.setItem("lastVisitedPage", window.location.href);
});

window.addEventListener("load", () => {
  const lastPage = sessionStorage.getItem("lastVisitedPage");
  const sessionRestored = sessionStorage.getItem("sessionRestored");

  if (!sessionRestored && lastPage && lastPage !== window.location.href) {
    sessionStorage.setItem("sessionRestored", "true"); //  this prevents multiple redirects
    window.location.href = lastPage;
  }
});

